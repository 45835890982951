import { useEffect, useCallback, useState } from 'react';

import styles from './index.module.sass';

// import CLOCK from './assets-contador/reloj-contador.png';
import OLEOS from './assets-contador/oleos.png';
import LOGO from './assets-contador/descuento-desk.png';
// import LOGO_MOV from './assets-contador/descuento.png';
import NEW from './assets-contador/nuevos-ingresos.png';
import FIFTY from './assets-contador/50-descuento.png';

export default function CountDown({ device, setShowCount, target_date }) {
  // const deviceWidth = device.width;
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  function pad(n) {
    return (n < 10 ? '0' : '') + n;
  }

  const getCountdown = useCallback(() => {
    var current_date = new Date().getTime();

    if (target_date < current_date) {
      setShowCount(false);
      return;
    }

    // find the amount of "seconds" between now and target

    var seconds_left = (target_date - current_date) / 1000;

    setHours(pad(parseInt(seconds_left / 3600)));
    seconds_left = seconds_left % 3600;

    setMinutes(pad(parseInt(seconds_left / 60)));
    setSeconds(pad(parseInt(seconds_left % 60)));
  }, [target_date, setShowCount]);

  useEffect(() => {
    const intervalId = setInterval(function () {
      getCountdown();
    }, 1000);

    return () => clearInterval(intervalId);
  }, [getCountdown]);

  return (
    <div className={styles.promoCountDown}>
      <img className={styles.toWho} alt='new patro' src={NEW} />
      <div className={styles.promoTypeContainer}>
        <div>
          <img src={LOGO} alt='Logo' className={styles.logo} />
        </div>
        <div>
          <img src={FIFTY} alt='50% off' className={styles.fifty} />
        </div>
      </div>
      <div className={styles.bottom}>
        <div>
          <img src={OLEOS} alt='Oleos' className={styles.clock} />
        </div>
        <div className={styles.counterContainer}>
          <div className={styles.cdPromoTitle}>
            {/* <div className={styles.cdtitle1}>QUEDAN POCAS HORAS</div> */}
            <div className={styles.cdtitle2}>¡APROVECHA YA!</div>
          </div>
          <div id={styles.countdown}>
            <div id={styles.tiles}>
              <div className={styles.numberContainer}>
                <span>{hours}</span>
                <div className={styles.labels}>Horas</div>
              </div>
              <div className={styles.numberContainer}>
                <span>{minutes}</span>
                <div className={styles.labels}>Mins</div>
              </div>
              <div className={styles.numberContainer}>
                <span>{seconds}</span>
                <div className={styles.labels}>Segs</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.note}>
        *DEL 13 al 18 de NOVIEMBRE. HASTA AGOTAR EXISTENCIAS. APLICAN RESTRICCIONES
      </div>
    </div>
  );
}
